import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, RenderInBody } from 'bv-components';
import { t, locale } from 'bv-i18n';
import { setLocation } from 'bv-helpers/location';

const formatDate = (date) => date.toLocaleDateString(locale());
const formatTime = (date) => date.toLocaleTimeString(locale(), {
  hour: 'numeric',
  minute: 'numeric',
});

const InfoModal = ({ onClose, expirationDate }) => {
  const closeModalAndLogoutUser = () => {
    onClose();
    setLocation('/logout');
  };

  useEffect(() => {
    const timeoutID = setTimeout(closeModalAndLogoutUser, 5000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, []);

  return (
    <RenderInBody>
      <Modal
        info
        icon
        actions={[
          {
            id: 'ok-btn',
            label: t('ok'),
            primary: true,
            inverse: true,
            onClick: closeModalAndLogoutUser,
          },
        ]}
      >
        <p>{t('panic_button.24h_confirmation_1')}</p>
        <p>
          {t('panic_button.24h_confirmation_2', {
            date: formatDate(expirationDate),
            time: formatTime(expirationDate),
          })}
        </p>
      </Modal>
    </RenderInBody>
  );
};

InfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  expirationDate: PropTypes.instanceOf(Date).isRequired,
};

export default InfoModal;
