import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'bv-components';
import { useFeature, useLoggedIn } from 'bv-hooks';
import { t } from 'bv-i18n';
import { useLongPress, LongPressEventType } from 'use-long-press';
import { enterAccountBreak } from '../api';
import InfoModal from './info_modal';
import FailureSubmitModal from './failure_submit_modal';

const PanicButtonContainer = ({ source }) => {
  const [panicBtnIsEnabled] = useFeature('panic_button');
  const [infoModalOpened, setInfoModalOpened] = useState(false);
  const [failureModalOpened, setFailureModalOpened] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);
  const [runBtnProgressAnimation, setRunBtnProgressAnimation] = useState(false);
  const loggedIn = useLoggedIn();

  const handleAccountBreakBtnClick = useCallback(() => {
    enterAccountBreak().then((response) => {
      if (response.success) {
        setExpirationDate(new Date(response.expirationDate));
        setInfoModalOpened(true);
      } else {
        setFailureModalOpened(true);
      }
    });
  }, []);

  const longPressHandler = useLongPress(handleAccountBreakBtnClick, {
    onStart: () => setRunBtnProgressAnimation(true),
    onFinish: () => setRunBtnProgressAnimation(false),
    onCancel: () => setRunBtnProgressAnimation(false),
    threshold: 3000,
    cancelOnMovement: false,
    detect: LongPressEventType.Pointer,
  });

  if (!panicBtnIsEnabled || !loggedIn) return null;

  const wrapperClassName = classnames('global-panic-button-wrapper', source);
  const btnClassName = classnames('global-panic-button', {
    active: runBtnProgressAnimation,
  });

  return (
    <>
      <div className={wrapperClassName}>
        {source !== 'casino-modal' && (
          <span>
            {t('panic_button.24h_break_long_desc')}
          </span>
        )}
        <Button
          alternative
          className={btnClassName}
          {...longPressHandler()}
        >
          <span>
            {t('panic_button.24h_break')}
          </span>
          <span>
            -
          </span>
          <span>
            {t('panic_button.24h_break_short_desc')}
          </span>
        </Button>
      </div>
      {infoModalOpened && (
        <InfoModal
          onClose={() => setInfoModalOpened(false)}
          expirationDate={expirationDate}
        />
      )}
      {failureModalOpened && (
        <FailureSubmitModal onClose={() => setFailureModalOpened(false)} />
      )}
    </>
  );
};

PanicButtonContainer.propTypes = {
  source: PropTypes.string.isRequired,
};

export default PanicButtonContainer;
