import PropTypes from 'prop-types';
import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';

const FailureSubmitModal = ({ onClose }) => (
  <RenderInBody>
    <Modal
      danger
      actions={[{
        id: 'ok-btn',
        label: t('close'),
        danger: true,
        inverse: true,
        onClick: onClose,
      }]}
    >
      <p>{t('panic_button.errors.request_is_not_completed')}</p>
      <p>{t('panic_button.errors.try_again')}</p>
    </Modal>
  </RenderInBody>
);

FailureSubmitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FailureSubmitModal;
